export const environment = {
  production: true,
  firebaseConfig : {
    apiKey: "AIzaSyDGW6qjcoEuSHLoI8DNe7j0Fw2hUVd-XJE",
    authDomain: "atelier-num.firebaseapp.com",
    projectId: "atelier-num",
    storageBucket: "atelier-num.appspot.com",
    messagingSenderId: "38303227782",
    appId: "1:38303227782:web:9ebef995861349fdf75607",
    measurementId: "G-KRH20VXBK9"
  }
};
