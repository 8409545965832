<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <!-- <mat-drawer mode="side" opened="true"> -->
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>

<div class="container-fluid page">
    <div class="d-flex page__box p-3 mt-2">Facturation</div>
    <div class="page__content shadow p-3 position-relative">
        <div class="page__content-data position-absolute text-center">
            Billing Content Goes Here
        </div>
    </div>
</div>

    </mat-drawer-content>
</mat-drawer-container>