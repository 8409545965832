<mat-drawer-container>
  <mat-drawer mode="side" [opened]="sideBarOpen">
    <!-- <mat-drawer mode="side" opened="true"> -->
    <app-sidenav></app-sidenav>
  </mat-drawer>
  <mat-drawer-content>
    <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
    <div class="container-fluid page">
        <div class="d-flex page__box p-3 mt-2">Test Form Array</div>
        <div class="page__content shadow p-3 position-relative">
            <div class="page__content-data position-absolute text-center">
                <h3>Add Folder:</h3>
                    <div class="add-lessons-form" [formGroup]="form">

                        <ng-container formArrayName="status">
                            <ng-container *ngFor="let folderForm of status.controls; let i = index">
                                <div class="lesson-form-row" [formGroup]="folderForm">
                                    <mat-form-field appearance="fill">
                                        <input matInput
                                            formControlName="title"
                                            placeholder="Status title">
                                    </mat-form-field>
                                    <!--<mat-form-field appearance="fill">
                                        <mat-select formControlName="status"
                                                placeholder="Status level">
                                            <mat-option value="notstarted">Pas commencé</mat-option>
                                            <mat-option value="inprogress">En cours</mat-option>
                                            <mat-option value="done">Terminé</mat-option>
                                        </mat-select>
                                    </mat-form-field>-->
                                    <mat-icon class="delete-btn" (click)="deleteFolder(i)">
                                        delete_forever</mat-icon>
                                </div>
                            </ng-container>
                        </ng-container>
                    
                        <button mat-mini-fab (click)="addFolder()">
                            <mat-icon class="add-course-btn">add</mat-icon>
                        </button>

                    </div>
            </div>
        </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


