<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <!-- <mat-drawer mode="side" opened="true"> -->
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
        <div class="container-fluid page">
            <div class="d-flex page__box p-3 mt-2 text-white">Fiche numérisation</div>
            <!--<div id="content" #content class="page__content shadow p-3 position-relative">-->
            <div id="content" #content class="page__content p-3 position-relative mt-5 ml-5" style="font-size: 23px;">
                <img src="./../../../../assets/logo_sekoia.png" alt="Logo Sekoia Services SA">
                <h2 class="mt-5 text-center" style="font-size: 45px!important;">Fiche de suivi</h2>
                <!--<div class="page__content-data position-absolute">-->
                <div class="row" style="margin-top: 50px;">
                    <div class="col-6 p-4">
                        <p *ngIf="singleFolder" class="">Client :  <strong>{{ singleFolder.nomClient }}</strong></p>
                    </div>
                    <div class="col-6 p-4">
                        <p *ngIf="singleFolder" class=""><span *ngIf="singleFolder.month">Mois</span><span *ngIf="singleFolder.month && singleFolder.year"> et </span> <span singleFolder.year> Année : </span><strong><span *ngIf="singleFolder.month">{{ singleFolder.month}}</span></strong>  <span><strong> {{ singleFolder.year }}</strong></span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 p-4">
                        <p *ngIf="singleFolder" class="">Nom du classeur : <strong>{{ singleFolder.nomClasseur }}</strong></p>
                    </div>
                    <div class="col-6 p-4">
                        <p *ngIf="singleFolder" class="">Répertoire de base : <strong>{{ singleFolder.directory | uppercase }} </strong></p>
                    </div>
                </div>
                <div class="border">
                    <h3 class="mt-5 mb-5 p-4 text-center">Données à utiliser pour la création du dossier scanné :</h3>
                    <div class="row">
                        <!--<div *ngIf="singleFolder.folder" class="col-6 p-4">
                            <p>Nom du (des) dossier(s) :</p>
                        </div>-->
                        <div class="col-6 p-4">
                            <p >Nom du (des) fichiers(s) PDF : </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 p-4">
                            <div *ngIf="singleFolder">
                                <div class="row">
                                    <div class="col-7">
                                        <strong><span *ngFor="let item of singleFolder.folder">
                                        <span>          \{{ item }}</span>
                                        </span></strong>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="singleFolder">
                                <div class="row">
                                    <div class="col-7">
                                        <strong><span *ngFor="let item of singleFolder.folderCustom">
                                        <span>{{ item.folderCustom }}.pdf</span><br>
                                        </span></strong>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                   
                        <!--<div class="col-6 p-4">
                            <p *ngIf="singleFolder" class=""><strong>{{singleFolder.nomClasseur}}<span *ngIf="singleFolder.specificite" style="color: rgb(15, 148, 224);">_{{singleFolder.specificite}}</span>.pdf</strong></p>
                        </div>-->
                    </div>
                </div>
                <div class="row">
                    <p class="text-center" style="text-decoration: underline;">À remplir par le stagiaire </p>
                    <div class="col-12">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">Numériseur</th>
                                    <th scope="col">Partie</th>
                                    <th scope="col">État</th>
                                    <th scope="col">Visa</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                   <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt-5" style="text-align: left!important;">
                    <div class="col-6 border p-4">
                        <h4  style="text-decoration: underline;">À remplir par le contrôleur n°1</h4>
                        <div class="row mt-3">
                            <div class="col-4">
                            <input type="checkbox"> OCR
                            </div>
                            <div class="col-8">
                               <input type="checkbox">  PDF/A-1b
                            </div>
                        </div>                     
                        <div class="row">
                            <div class="col-4">
                                <input type="checkbox"> Dossier
                            </div>
                            <div class="col-8">
                                <input type="checkbox"> Fusion des parties
                            </div>
                        </div>
                        <p class="mt-3"><strong>Nombre de fichiers : </strong></p>
                        <p class=""><strong>Nombre de pages : </strong></p>
                        <div class="row">
                            <div class="col-7">
                                <p class=""><strong>Contrôle 1 fait par : </strong></p>
                            </div>
                            <div class="col-5">
                                <p class=""><strong>Visa :</strong></p>
                            </div>
                        </div>
                        <br><br>
                        <p class=""><strong>Le : </strong></p>
                        <p class=""><strong>Commentaires : </strong></p>
                        <p>_____________________________________________________________</p>
                        <p>_____________________________________________________________</p>
                        <p>_____________________________________________________________</p>
                    </div>
                    <div class="col-6 border p-4">
                        <h4  style="text-decoration: underline;">À remplir par le contrôleur n°2</h4>
                        <div class="row mt-3">
                            <div class="col-4">
                            <input type="checkbox"> OCR
                            </div>
                            <div class="col-8">
                               <input type="checkbox">  PDF/A-1b
                            </div>
                        </div>                     
                        <div class="row">
                            <div class="col-4">
                                <input type="checkbox"> Dossier
                            </div>
                            <div class="col-8">
                                <input type="checkbox"> Fusion des parties
                            </div>
                        </div>
                        <p class="mt-3"><strong>Nombre de fichiers : </strong></p>
                        <p class=""><strong>Nombre de pages : </strong></p>
                        <div class="row">
                            <div class="col-7">
                                <p class=""><strong>Contrôle 2 fait par : </strong></p>
                            </div>
                            <div class="col-5">
                                <p class=""><strong>Visa :</strong></p>
                            </div>
                        </div>
                        <br><br>
                        <p class=""><strong>Le : </strong></p>
                        <p class=""><strong>Commentaires : </strong></p>
                        <p>_____________________________________________________________</p>
                        <p>_____________________________________________________________</p>
                        <p>_____________________________________________________________</p>
                    </div>
                </div>
                <p>Date de création du classeur : {{singleFolder.createdAt.toDate() | date:'dd MMMM yyyy'}} </p>
            </div>
            <div class="p-3">
                <div class="text-center">
                    <div class="mt-100 text-center">
                        <!--<p> {{folder.nomClient | uppercase }}</p>
                            <p>État : {{folder.etat }}</p>
                            <p>Comment : {{folder.comment }}   
                            </p>-->
                        <button class="btn btn-primary mr-10" type="button" (click)="goBack()">Retour</button>
                        <button class="btn btn-primary" type="button" (click)="openPDF()">Exporter en PDF</button>
                    </div>
                </div>
            </div>
        </div>


    </mat-drawer-content>
</mat-drawer-container>