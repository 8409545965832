<mat-nav-list>
    <div class="logo py-4">
        <img src="https://sekoia.ch/wp-content/uploads/2017/11/sekoia-logo.svg" alt="Sekoia Services SA Logo" />
    </div>
    <mat-divider></mat-divider>
    <!--<h2 matSubheader class="mt-2">APPLICATIONS</h2>-->

    <!--<a mat-list-item routerLinkActive="list-item-active" routerLink="/home">
        <mat-icon>home</mat-icon> Accueil
    </a>-->
    <div *ngFor="let u of displayNameObs">
    <a *ngIf="u.isAdmin" mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard">
        <mat-icon>dashboard</mat-icon>Dashboard
    </a>
     <a mat-list-item routerLinkActive="list-item-active" routerLink="/folder">
        <mat-icon>folder</mat-icon> Atelier Numérisation
    </a>
    <!--<a *ngIf="u.isAdmin" mat-list-item routerLinkActive="list-item-active" routerLink="/admin-folder">
        <mat-icon>folder</mat-icon> Admin Atelier Numérisation
    </a>-->
    <a *ngIf="u.isAdmin" mat-list-item routerLinkActive="list-item-active" routerLink="/social">
        <mat-icon>supervised_user_circle</mat-icon>Département Social
    </a>
    <a *ngIf="u.isAdmin" mat-list-item routerLinkActive="list-item-active" routerLink="/user">
        <mat-icon>perm_identity</mat-icon>
        Utilisateurs
    </a>
   
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/profil">
        <mat-icon>perm_identity</mat-icon> Profil
    </a> 
    </div>
    

    <!--<a mat-list-item routerLinkActive="list-item-active" routerLink="/stats">
        <mat-icon>assessment icon</mat-icon> Statistiques
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/billing">
        <mat-icon>attach_money</mat-icon> Facturation
        </a> -->

    <!--<a mat-list-item>
        <mat-icon>edit</mat-icon> Idée Fiche stagiaire (Suivi, test)
    </a>-->
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/contact">
        <mat-icon>mail_outline</mat-icon> Contact
    </a>
</mat-nav-list>