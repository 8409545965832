<mat-toolbar>
    <mat-toolbar-row class="justify-content-between">
        <button mat-icon-button (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
        <div class="row mr-2 ml-auto" *ngIf="authService.userData as user">
            <ul class="row m-0 align-items-center">
                <li>
                    <button mat-button [matMenuTriggerFor]="menu" class="user mt-2 d-flex align-items-center">
            <!--<img src="./assets/user.jpg" alt="" class="user-image mr-1 p-2" />-->
            <!-- <span><strong>Name : {{user.displayName}} - {{user.email}}</strong></span> -->
            <span *ngFor="let u of displayNameObs"><strong>
              [{{u.displayName}} - {{u.company}}]
            </strong></span>
            <mat-icon class="user-image-icon ml-1"
              >keyboard_arrow_down</mat-icon
            >
          </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="logout()">
              <mat-icon>exit_to_app</mat-icon>
              Se déconnecter
            </button>
                    </mat-menu>
                </li>
            </ul>

            <div>

            </div>

        </div>
    </mat-toolbar-row>
</mat-toolbar>