<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <!-- <mat-drawer mode="side" opened="true"> -->
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>
        <div class="container-fluid page">
                        
            <div class="d-flex page__box p-3 mt-2">Atelier Numérisation</div>
            <div class="page__content shadow p-3 position-relative">
                <!--<div class="page__content-data position-absolute text-center">-->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="icon-card mb-30">
                                <div class="icon purple">
                                    <mat-icon>line_weight</mat-icon>
                                </div>
                                <div class="content">
                                    <h6 class="mb-10">Nombre de classeurs</h6>
                                    <h3 *ngIf="foldersByUid$ | async as folders" class="text-bold mb-10">{{folders.length}}</h3>
                                    <!--<p class="text-sm text-success">
                                    <i class="fas fa-address-book"></i> +2.00%
                                    <span class="text-gray">(30 days)</span>
                                </p>-->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <!--<div class="icon-card mb-30">
                            <div class="icon purple">
                                <mat-icon>supervisor_account</mat-icon>
                            </div>
                            <div class="content">
                                <h6 class="mb-10">Nombre d'administrateurs</h6>
                                <h3 class="text-bold mb-10">3</h3> 
                            </div>
                        </div>-->
                        </div>
                        <div class="col-lg-4 col-md-6 text-right">
                            <button mat-raised-button color="warn" (click)="openDialog()">
                            <mat-icon inline=true>note_add</mat-icon>
                            Ajouter un classeur
                        </button>
                        </div>
                    </div>
                    <mat-form-field appearance="standard" style="width:100%">
                        <mat-label>Filter</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. FOJ" #input>
                    </mat-form-field>
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort style="width:100%">
                            <ng-container matColumnDef="nomClasseur">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Classeur </th>
                                <td mat-cell *matCellDef="let row"> {{row.nomClasseur | uppercase }} </td>
                            </ng-container>
                            <ng-container matColumnDef="directory">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Répertoire </th>
                                <td mat-cell *matCellDef="let row"> {{row.directory}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="month-year">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Mois et année</th>
                                <td mat-cell *matCellDef="let row">{{row.month}} {{row.year}} </td>
                            </ng-container>
                             <ng-container matColumnDef="state">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> État </th>
                                <td mat-cell *matCellDef="let row"> {{row.state}} </td>
                            </ng-container>
                            <!--<ng-container matColumnDef="comment">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Commentaire </th>
                                <td mat-cell *matCellDef="let row"> {{row.comment}} </td>
                            </ng-container>-->
                            <ng-container matColumnDef="createdAt">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Créé le </th>
                                <td mat-cell *matCellDef="let row"> {{row.createdAt.toDate() | date: 'dd/MM/yy'}} </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action </th>
                                <td mat-cell *matCellDef="let row" style="width: 155px;">
                                    <button mat-icon-button color="primary" (click)="viewFolder(row.id)">
                                        <mat-icon>find_in_page</mat-icon>
                                    </button>
                                    <button mat-icon-button color="primary" (click)="editFolder(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-icon-button color="warn" (click)="deleteFolder(row.id)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>