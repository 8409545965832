<mat-drawer-container>
    <mat-drawer mode="side" [opened]="sideBarOpen">
        <!-- <mat-drawer mode="side" opened="true"> -->
        <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
        <app-header (toggleSidebarForMe)="sideBarToggler()"></app-header>

        <div class="container-fluid page">
            <div class="d-flex page__box p-3 mt-2">Dashboard</div>
            <div class="page__content shadow p-3 position-relative">
                <!--<div class="page__content-data position-absolute text-center">-->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                                    <div class="icon-card mb-30">
                                        <div class="icon purple">
                                            <mat-icon>folder</mat-icon>
                                        </div>
                                        <div class="content">
                                            <h6 class="mb-10">Nombre de classeurs</h6>
                                            <h3 *ngIf="this.folders$ | async as folders" class="text-bold mb-10 ">{{ folders.length }}</h3>
                                            <h3></h3>
                                            <!--<p class="text-sm text-success">
                                            <i class="fas fa-address-book"></i> +2.00%
                                            <span class="text-gray">(30 days)</span>
                                        </p>-->
                                        </div>
                                    </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="icon-card mb-30">
                            <div class="icon purple">
                                <mat-icon>supervisor_account</mat-icon>
                            </div>
                            <div class="content">
                                <h6 class="mb-10">Nombre d'administrateurs</h6>
                                <h3 class="text-bold mb-10">3</h3>    
                            </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="icon-card mb-30">
                            <div class="icon purple">
                                <mat-icon>perm_identity</mat-icon>
                            </div>
                            <div class="content">
                                <h6 class="mb-10">Nombre d'utilisateurs</h6>
                                <h3 class="text-bold mb-10">7</h3>    
                            </div>
                            </div>
                        </div>
                    </div>
                      
                     <div class="row mt-5">
                          <div class="col-lg-4 col-md-6">
                            <a class="d-block text-decoration-none" routerLink="/folder">
                                <div class="icon-card mb-30">
                                    <div class="icon blue">
                                        <mat-icon>folder</mat-icon>
                                    </div>
                                    <div class="content">
                                        <h4 class="black-color">Atelier Numérisation</h4>  
                                    </div>  
                                </div>  
                            </a>
                        </div>   

                        <div class="col-lg-4 col-md-6">
                            <a class="d-block text-decoration-none" routerLink="/social">
                                <div class="icon-card mb-30">
                                    <div class="icon red">
                                        <mat-icon>supervised_user_circle</mat-icon>
                                    </div>
                                    <div class="content">
                                        <h4 class="black-color">Département Social (Coming soon)</h4>
                                        <!--<h3 *ngIf="this.folders$ | async as folders" class="text-bold mb-10 ">{{ folders.length }}</h3>-->
                                       
                                        <!--<p class="text-sm text-success">
                                        <i class="fas fa-address-book"></i> +2.00%
                                        <span class="text-gray">(30 days)</span>
                                    </p>-->
                                    </div>
                                </div>
                            </a>
                        </div>
                       
                      
                         <div class="col-lg-4 col-md-6">
                            <a class="d-block text-decoration-none"  routerLink="/user">
                            <div class="icon-card mb-30"> 
                                <div class="icon blue">
                                        <mat-icon>perm_identity</mat-icon>
                                </div>
                                <div class="content">
                                    <h4 class="black-color">Utilisateurs</h4>
                                        <!--<h4 class="black-color">Gestion des congés</h4>--> 
                                </div>
                            </div>
                            </a>
                        </div>     
                    </div>
               
                    <div class="row mt-5">
                        <div class="col-lg-4 col-md-6">
                            <!--<a class="d-block text-decoration-none" routerLink="/stats">-->
                                <div class="icon-card mb-30">
                                    <div class="icon red">
                                        <mat-icon>horizontal_split</mat-icon>
                                    </div>
                                    <div class="content">
                                        <h4 class="black-color">Statistiques <br>(Coming soon)</h4>
                                        <!--<h3 *ngIf="this.folders$ | async as folders" class="text-bold mb-10 ">{{ folders.length }}</h3>-->
                                       
                                        <!--<p class="text-sm text-success">
                                        <i class="fas fa-address-book"></i> +2.00%
                                        <span class="text-gray">(30 days)</span>
                                    </p>-->
                                    </div>
                                </div>
                            <!--</a>-->
                        </div>
                       
                        <div class="col-lg-4 col-md-6">
                            <!--<a class="d-block text-decoration-none" routerLink="/billing">-->
                                <div class="icon-card mb-30">
                                    <div class="icon blue">
                                        <mat-icon>receipt</mat-icon>
                                    </div>
                                    <div class="content">
                                        <h4 class="black-color">Facturation <br>(Coming soon)</h4>  
                                    </div>  
                                </div>  
                            <!--</a>-->
                        </div>   
                         <div class="col-lg-4 col-md-6">
                            <!--<a class="d-block text-decoration-none"  routerLink="/">-->
                            <div class="icon-card mb-30"> 
                                <div class="icon blue">
                                        <mat-icon>store_mall_directory</mat-icon>
                                </div>
                                <div class="content">
                                    <h4 class="black-color">Demande de congé (Coming soon)</h4>
                                </div>
                            </div>
                            <!--</a>-->
                        </div>     
                    </div>
                </div>
            </div>
        </div>

    </mat-drawer-content>
</mat-drawer-container>